<template>
  <b-row align-v="center" align-h="end">
    <b-col>
      <h1 class="text-dark">{{ title }}</h1>
      <h4>{{ subtitle }}</h4>
    </b-col>
    <b-col align-self="end" class="mb-1">
      <slot name="header-left"></slot>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
export default {
  name: "AppPageHeader",
  props: {
    title: String,
    subtitle: String
  },
  components: { BRow, BCol }
}
</script>

<style scoped>

</style>
