<template>
  <div>
    <b-card class="bg-transparent mb-0">
      <AppPageHeader subtitle="Visualize aqui o detalhamento da operação selecionada" title="Detalhes da operação">
        <template #header-left>
          <div class="d-flex justify-content-end align-items-center">
            <b-button variant="outline-primary" size="sm"
              @click="$router.push({ name: 'admin-concessions.index' })">Voltar</b-button>
          </div>
        </template>
      </AppPageHeader>
    </b-card>
    <b-card>
      <b-row>
        <b-col cols="6">
          <b-card class="cc-bg-light mb-0 h-100">
            <div class="d-flex justify-content-between mb-1">
              <span>Status
              </span>
              <span class="d-flex justify-content-end">
                <div v-html="comercialNoteStatusBadgeForAdmin(nota_comercial.status)" />
              </span>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">Número da nota</h6>
              <h6 class="mb-1">{{ nota_comercial.numero }}</h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">CNPJ</h6>
              <h6 class="mb-1">{{ nota_comercial.empresa.cnpj }}</h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">Nome fantasia</h6>
              <h6 class="mb-1">{{ nota_comercial.empresa.nome_fantasia }}</h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1 text-dark" ref="totalAPagarTitulo">Total a pagar no vencimento</h6>
              <h6 class="mb-1 text-dark" ref="totalAPagarValor">
                {{ moneyFormat(nota_comercial.valor_devido) }}
              </h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="mb-1">Total solicitado</h6>
              <h6 class="mb-1">{{ moneyFormat(nota_comercial.valor_emissao) }}</h6>
            </div>
          </b-card>
        </b-col>
        <b-col class="card-max-height" cols="6">
          <b-card class="cc-bg-light mb-0 h-100">
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="text-separator">Prazo - dias</h6>
              <h6 class="text-separator">{{ nota_comercial.prazo }}</h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="text-separator">Taxa de juros</h6>
              <h6 class="text-separator">{{ nota_comercial.taxa_juros.toString().replace('.', ',') }}%</h6>
            </div>
            <div v-if="!taxaEscrituracaoIsNullOrZero" class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="text-separator">Taxa de Escrituração</h6>
              <h6 class="text-separator">
                {{ nota_comercial.taxa_escrituracao.toString().replace('.', ',') }}%
              </h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="text-separator">
                Taxa efetiva no período
              </h6>
              <h6 class="text-separator">
                {{ nota_comercial.taxa_efetiva_periodo.toString().replace('.', ',') }}%
              </h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="text-separator">Data de vencimento</h6>
              <h6 class="text-separator">{{ formatDate(nota_comercial.data_vencimento) }}</h6>
            </div>
            <div class="d-flex flex-row justify-content-between align-items-center">
              <h6 class="text-separator">Data de resgate</h6>
              <h6 class="text-separator">{{ formatDate(nota_comercial.data_emissao) }}</h6>
            </div>
          </b-card>
          <div class="d-flex mt-1">
            <flat-pickr v-model="base_date" class="form-control mb-1 bg-white" :config="config" placeholder="Data base de calculo de juros"/>
            <feather-icon class="border-icon" icon="CalendarIcon" size=38 color="rgba(9, 50, 114, 1)" />
            <b-button class="juros-size ml-1" variant="primary" @click="callFeeCalculator"  :disabled="disableButton" >
              Calcular juros
            </b-button>
            <p class="mx-1 align-self-center">ou</p>
            <b-button class="juros-size" variant="outline-primary" @click="callFeeCalculatorWithLiquidation"  :disabled="disableButton" >
              Liquidar nota
            </b-button>
          </div>
        </b-col>
        <b-col cols="12">
          <div class="error-field mt-2 p-1" v-show="toggle">
            <feather-icon icon="AlertTriangleIcon" size=20 color="rgba(252, 6, 94, 1)" />
            <p class="align-self-center mb-0 ml-1"><strong>Atenção:</strong></p>
            <p class="mb-0 ml-1" ref="errorField"></p>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import AppPageHeader from "@core/components/app-page-header/AppPageHeader";
import { BCard, BCol, BRow, BButton, BBadge, VBTooltip } from "bootstrap-vue";
import {
  moneyFormat,
  dateFormatter,
  percentageFormatter,
  statusBadgeVariant,
  comercialNoteStatusBadgeForAdmin,
} from "@core/comp-functions/data_visualization/datatable";
import format from "date-fns/format";
import parse from "date-fns/parse";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from 'flatpickr/dist/l10n/pt.js';
import axios from "axios";

export default {
  name: "ShowConcession",
  components: {
    AppPageHeader,
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    flatPickr,
  },
  filters: {
    moneyFormat,
    dateFormatter,
    percentageFormatter,
    statusBadgeVariant,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mounted() {
    this.setData();
  },
  data() {
    return {
      nota_comercial: {},
      statusesNames: {
        aguardando_transferencia: "Processando",
        vencida: "Vencida",
        executada: "Depositado",
        liquidada: "Liquidada",
        aguardando_assinatura: "Aguardando assinatura",
        cancelada: "Cancelada",
      },
      base_date: '',
      config: {
        dateFormat: "d/m/Y",
        locale: Portuguese,
      },
      toggle: false
    };
  },
  methods: {
    async setData() {
      const id = this.$route.params.id;
      const { data } = await this.$store.dispatch("concession/adminFetch", id);
      this.nota_comercial = data;
    },
    comercialNoteStatusBadgeForAdmin(status) {
      return comercialNoteStatusBadgeForAdmin(status);
    },
    moneyFormat(value, minimumFractionDigits = 2) {
      return moneyFormat(value, minimumFractionDigits);
    },
    formatDate(value) {
      if (!value) return "";

      let date = parse(value, "yyyy-MM-dd", new Date());

      return format(date, "dd/MM/yyyy");
    },
    async callFeeCalculator() {
      this.getPreviewEncargosWithNewDate(this.base_date);
    },
    async callFeeCalculatorWithLiquidation() {
      await this.sendCalculatorRequest(`/concessao/notas_comerciais/${this.nota_comercial.id}/calcular_valor_devido_e_liquida`)
    },
    async sendCalculatorRequest(url) {
      this.toggle = false
      const axiosIns = axios.create({
        baseURL: process.env.VUE_APP_API_BASE_URL,
      });

      await axiosIns.post(
        url,
        { params: {
            date: this.base_date
          }
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${localStorage.getItem('accessToken')}`
          },
          params: {
            date: this.base_date
          }
        }
      ).then(() => {
        this.setData();
        this.$refs.totalAPagarTitulo.classList.add('text-color-red')
        this.$refs.totalAPagarValor.classList.add('text-color-red')
      }).catch((erro) => {
        this.toggle = true
        this.$refs.errorField.textContent = ''
        this.$refs.errorField.textContent = erro.response.data[0]
      })
    },
    async getPreviewEncargosWithNewDate(newDate) {
      try {
        const response = await this.$store.dispatch('concession/getPreviewEncargos', { id: this.$route.params.id, data_pagamento: newDate})

        const valor_com_encargos = response.data.valor_devido_com_atraso

        this.nota_comercial.valor_devido = valor_com_encargos
        this.$refs.totalAPagarTitulo.classList.add('text-color-red')
        this.$refs.totalAPagarValor.classList.add('text-color-red')
      } catch (erro) {
        this.toggle = true
        this.$refs.errorField.textContent = ''
        this.$refs.errorField.textContent = erro.response.data[0]
        console.error('Erro ao chamar a API:', error);
      }
    },
  },
  computed: {
    statusLabel() {
      return this.statusesNames[this.nota_comercial.status];
    },
    taxaEscrituracaoIsNullOrZero() {
      return (
        this.nota_comercial.taxa_escrituracao === "0.0" ||
        this.nota_comercial.taxa_escrituracao === null
      );
    },
    disableButton() {
      if(this.base_date !== '' && this.base_date !== null && this.base_date !== undefined) {
        return false
      }

      return true
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.juros-size {
  white-space: nowrap;
  text-align: center;
  max-height: 37px;
  // margin-left: 10px;
}

.card-max-height {
  max-height: 180px;
}

.border-icon {
  border: solid 1px rgba(9, 50, 114, 1);
  padding: 5px;
  margin-left: -3px;
  background-color: white;
  border-radius: 0px 6px 6px 0px;
}

.text-color-red {
  color: rgba(252, 6, 94, 1) !important;
}

.text-separator {
  margin-bottom: 10px;
}

.error-field {
  background-color: rgba(252, 6, 94, 0.1);
  border: solid 1px rgba(252, 6, 94, 1);
  border-radius: 6px;
  display: flex;
}
</style>
